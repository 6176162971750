@import '../../styles/customMediaQueries.css';
.cardContent{
  cursor: pointer;
}
.arabicDirection{
  & .title, & .authorName, & .priceAmount{
    text-align: right !important;
  }
  & .wishListIcon, & .likeIconWrapper{
    right: unset !important;
    left: 8px;
  }
  & .priceAmount{
    display: flex;
    gap: 4px;
  }
}
.bookOnWhatsapp{
  /* font-family: SF Pro Text; */
font-weight: 600;
font-size: 12px;
line-height: 22px;
letter-spacing: 0px;
text-align: center;
color: #32D851;

display: flex;
gap: 6px;
align-items: center;
margin: 20px 0 0;
}
.servicesListBox {
  &:hover {
    text-decoration: none;
  }

  & .serviceCard {
    position: relative;
    & .wishListIcon {
      top: 6px;
      right: 8px;
      position: absolute;
      cursor: pointer;
      & svg {
        & path {
          fill: #C8C7CC;
        }
      }
    }

    & .likeIconWrapper {
      top: 6px;
      right: 8px;
      position: absolute;
      cursor: pointer;
      & svg {
        & path {
          fill: #ff0000;
        }
      }
    }
    & .imageWrapper {
      height: 163px;
      & img , & svg{
        height: 163px;
        object-fit: cover;
        border-radius: 8px;
        width: 100%;
      }

  
    }

    & .detailWrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 12px;
      gap: 8px;
      & .titleDetails {
        & .title {
          font-family: Actor;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: 0px;
          
          color: #000000;
          margin: 0;
        }

        & .authorName {
          margin: 0;
          /* font-family: SF Pro Text; */
          font-weight: 400;
          font-style: italic;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0px;
          
          text-align: left;
          margin-bottom: 5px;
          color: #8A8A8F;
        }

        & .priceAmount {
          font-family: Actor;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: 0px;
          
          color: #8A8A8F;
        }

        & .reviewDetails {
          & .reviewNumber {
            font-size: 13px;
            font-weight: 500;
            line-height: 18px;
            text-align: left;
            color: #8A8A8F;
            padding-left: 4px;
          }
        }
      }

      & .buttonWrapper {
        display: flex;
            flex-direction: column;
            align-items: flex-end;
        & button {
          border: 2px solid #FFD046;
          /* font-family: SF Pro Text; */
          font-weight: 600;
          font-size: 17px;
          line-height: 22px;
          letter-spacing: 0px;
          text-align: center;
          margin-top: 2px;
          cursor: pointer;
          
          text-align: center;
          color: #000;
          width: fit-content;
          min-height: 0;
          background-color: #ffffff;
          border-radius: 45px;
          padding: 5px 24px;
        }
      }
    }
  }
}